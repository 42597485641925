import $ from 'jquery';

  // Toggle Nav
// const toggleMenu = () => {

  const burger = document.querySelector('.menu-icon');
  const menuBtn = document.querySelector('.title-bar');
  const nav = document.querySelector('.top-bar');
  const navLinks = document.querySelectorAll('.menu li');
  // const bars = document.querySelectorAll('.title-bar-title');
  const header = document.querySelector('header');


$(burger).on('click', function () {
    nav.classList.toggle('open');
    menuBtn.classList.toggle('open');
    // header.classList.toggle('open');
    if(header.classList.contains('open')) {
      console.log('ok');
      setTimeout(function() {
        header.classList.toggle('open');
      }, 1000);
    } else {
      header.classList.toggle('open');
    }
    // console.log('toggle');
    //Animate Links
    navLinks.forEach((link, index) => {
      if(link.style.animation) {
        link.style.animation = '';
      } else {
        link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.3}s`;
      }
    });
    // resetOnClick();
  });
// }

$(navLinks).on('click', function () {
  nav.classList.toggle('open');
  menuBtn.classList.toggle('open');

  navLinks.forEach((link, index) => {
    if(link.style.animation) {
      link.style.animation = '';
    } else {
      link.style.animation = `navLinkFade 0.5s ease forwards ${index / 7 + 0.3}s`;
    }
  });
  if(header.classList.contains('open')) {
    console.log('ok');
    setTimeout(function() {
      header.classList.toggle('open');
    }, 1000);
  } else {
    header.classList.toggle('open');
  }
});
  // /* Reset the menu on click of one of menu elements */


/* Menu Global Variables */
// var menu = $('.menu-icon');
// var menuBtn = $('.title-bar');
// var whiteDiv = $('.top-bar');
// var mainContainer = $('.header');
// // var open = $('.open');
//
// /* START TOGGLE MENU FUNCTIONS */
// /* Toggle menu on click of the hamburger icon */
// $(menu).on('click', function () {
//   	$(this).toggleClass('open');
//     menuBtn.toggleClass('open');
//     whiteDiv.toggleClass('open');
//     mainContainer.toggleClass('open');
//     noScroll();
// });
//

// $(menuItem).on('click', function () {
//   menuBtn.toggleClass('open');
//   menu.toggleClass('open');
//   // whiteDiv.css('display', 'none');
//   if(whiteDiv.hasClass('open')) {
//     whiteDiv.removeClass('open');
//   }
//   mainContainer.toggleClass('open');
//   noScroll();
// });

/*
  Function to prevent the body to scroll when the menu is opened on mobile screens
  and to allow to scroll again when the menu is closed
*/
// function noScroll() {
//   var width = $(window).width();
//     if (width < 768) {
//       if (!menu.hasClass('open')) {
//         document.body.style.overflow = "";
//         // whiteDiv.css('display', 'none');
//       } else {
//         document.body.style.overflow = "hidden";
//         // if (width > 768) {
//         //   whiteDiv.css('display', 'none');
//         // }
//       }
//     }
// }
/* END TOGGLE MENU FUNCTIONS */

/*
  On click of the navigations elements of scrollbar go to the selected element
*/
$(document).ready(function() {
   $('li.showcase').click(function() {
     console.log('work');
     $('html, body').animate({
       scrollTop: $("#showcase").offset().top
     }, 1000);
     return false;
    }),
    $('li.about').click(function() {
      console.log('bio');
      $('html, body').animate({
        scrollTop: $(".bio").offset().top
      }, 1000);
      return false;
    }),
    $('li.contact-form').click(function() {
      console.log('work');
      $('html, body').animate({
        scrollTop: $(".contact").offset().top
      }, 1000);
      return false;
    }),
    $('.down').click(function() {
      $('html, body').animate({
        scrollTop: $("#showcase").offset().top
       }, 1000)
    }),
    $('.scrollup').click(function() {
      $('html, body').animate({
        scrollTop: $("body").offset().top
       }, 1000)
    })
    // $('.form .button').click(function() {
    //   $('html, body').animate({
    //     scrollTop: $(".contact").offset().top
    //    }, 1000)
    // })
    // $('form .button').click(function() {
    //   sessionStorage.reloadAfterPageLoad = true;
    //   window.location.reload();
    //   $( function () {
    //     if ( sessionStorage.reloadAfterPageLoad ) {
    //       $('html, body').animate({
    //         scrollTop: $(".contact").offset().top
    //        }, 1000)
    //       sessionStorage.reloadAfterPageLoad = false;
    //     }
    //   });
    // })
 });

var homepage = $('.home');
/*
  Types.js Animation on h1 and h3 in the Hero section
*/
$(document).ready(function() {
  if (homepage) {
    var typed = new Typed('.name', {
      strings: ["Francesco D'Aloé"],
      typeSpeed: 55,
      startDelay: 800
    });

    var typed = new Typed('.subtitle', {
      strings: ["Front-End &amp; Web Developer"],
      typeSpeed: 55,
      startDelay: 800
    });
  }
});


 /* SHRINK HEADER */
 window.onscroll = function() {
   scrollFunction();
   scrollup();
 };
 /*
   Function to add the class desktop-shrink when scrolling the page
   so that the navigation background-color become white
 */
 function scrollFunction() {
   var width = $(window).width();
   var navBar = $('.nav-bar ');
   if (width > 768) {
     if (document.body.scrollTop > 150 || document.documentElement.scrollTop > 150) {
       navBar.addClass('desktop-shrink');
     } else {
       navBar.removeClass('desktop-shrink');
     }
   } else {
     // if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
     //   navBar.addClass('mobile-shrink');
     // } else {
     //   navBar.removeClass('mobile-shrink');
     // }
   }
 }


function scrollup() {
  var scrollup = $('.scrollup');
  if (document.body.scrollTop > 800 || document.documentElement.scrollTop > 800) {
    scrollup.css('opacity', 1);
  } else {
    scrollup.css('opacity', 0);
  }
}

 /*
  function to show the banner once the user
  click one card of the first row and 3 cards of the second row
 */
 $(document).ready(function(){

   /*
   *** first row - function to select the developer side ***
   */
   var $side = $('.side .skill'),
   $tech = $('.tech .skill');
   // side card
   $side.click( function(){
     var $this = $(this);
     $side.removeClass('selected');
     $this.addClass('selected');

     // check if we can show footer
     popupFooter();
   });

   // Tech cards
   $tech.click( function(){
     var $this = $(this);
      $(this).addClass('selected');
     // if( $this.hasClass('selected') ) {
     //   $this.removeClass('selected');
     //   console.log('Was selected before. Set it unselected.');
     //   console.log('We have less than 3 features selected');
     // } else {
     //   if( $('.tech .skill.selected').length <3 ) {
     //     $(this).addClass('selected');
     //     console.log('We have 3 cards selected');
     //   } else {
     //     // do nothing as 3 cards selected
     //     console.log('We have 3 cards selected yet');
     //   }
     // }

   // check if we can show footer
   popupFooter();

 });

 function popupFooter() {
   var x = $('.callout .title-bar');
   if( $('.side .skill.selected').length && $('.tech .skill.selected').length === 3 ) {
     $('.pop-up').css('display', 'block');
     x.css('display', 'block');
     console.log('Footer info ON');
   } else {
     $('.pop-up').css('display', 'none');
     console.log('Footer info OFF');
   }
 }
 });

 /* CV page - Close PopUp */
 function resetGrid() {
   $('.callout').click(function() {
     $('.skill.selected').removeClass('selected');
     $('.pop-up').hide();
   });
 }
 resetGrid();


var card = $('.item');
var desc = $('.description');
var $this = $(this);

  $(card).hover(
    function() {
      setTimeout(function() {
        $(this).addClass('hovered'); }.bind(this), 1500)
    },
    function(){ $(this).removeClass('hovered') }
  )


  // Initialize Slick
    $(document).ready(function(){
      $('.react-card').not('.slick-initialized').slick({
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000
      });
    });


// Initialize AOS
AOS.init();
